import PropTypes from 'prop-types';
import cn from 'classnames';
import { useState } from 'react';
import styles from './index.module.scss';
import Text from '../Text';
import SizeTooltip from './SizeTooltip';
import useDeviceType from '@/hooks/useDeviceType';

const SizeItem = ({
  size,
  onClick,
  isSelected = false,
  textSize = 'm',
  noCount = false,
  equivalenceTable = {}
}) => {
  //   const storeId = useSelector((state) => state.store);
  const storeId = 'city';
  const classContainer = cn({
    [styles.container]: true,
    [styles.checked]: isSelected,
    [styles.noCount]: noCount
  });
  const [hover, setHover] = useState(false);
  const { isDesktop } = useDeviceType();

  const applyColor = () => {
    const selectedColor = isSelected
      ? 'var(--color-primary)'
      : 'var(--color-white)';
    return noCount ? 'var(--color-disable-size)' : selectedColor;
  };

  return (
    <div className={styles.wrapper}>
      <li
        title={size}
        className={classContainer}
      >
        <button
          onClick={() => {
            if (!isDesktop) {
              setHover(true);
              setTimeout(() => {
                setHover(false);
              }, 3000);
            }
            onClick();
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          disabled={noCount}
          className={storeId === 'city' && styles.buttonCity}
        >
          <Text
            textColor={applyColor()}
            textSize={textSize}
          >
            {size}
          </Text>
          {noCount && <div className={styles.dash} />}
        </button>
      </li>
      {equivalenceTable && (
        <SizeTooltip
          equivalenceTable={equivalenceTable}
          visible={hover}
        />
      )}
    </div>
  );
};

SizeItem.propTypes = {
  size: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  textSize: PropTypes.string,
  noCount: PropTypes.bool,
  equivalenceTable: PropTypes.object
};

export default SizeItem;
